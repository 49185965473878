import React from 'react'

import {useTranslation} from '../translation'
import './Solution.css'


const toPercentage = fr => {
    return fr.mul(100).valueOf()
}


let totalCount = 0;
let no_heirs_list =[]


const findTotalCount = (results) => {
    totalCount = 0;
    for (let i = 0; i < results.length; i++) {
        totalCount = totalCount + results[i].count;
    }
}

function gcd(a, b) {
    if (isNaN(a)) {
        a = 0;
    }
    if (isNaN(b)) {
        b = 0;
    }
    console.log(a + "fdcsc" + b);
    // if (!a || !b) {
    //     return;
    // }
    if (b === 0)
        return a;
    return gcd(b, a % b);
}

let lcm = 1;
let gcdVal = 1;

function findlcm(arr) {
    // Initialize result
    let ans = arr[0];

    // ans contains LCM of arr[0], ..arr[i]
    // after i'th iteration,
    for (let i = 1; i < arr.length; i++) {
        ans = (((arr[i] * ans)) / (gcd(arr[i], ans)));

        // console.log(arr[i], ans);
        // console.log("ugdsfufgsdgdgvgduhfyg");
    }
    lcm = ans;
    return ans;
}


// Function to find gcd of array
// of numbers
function findGCD(arr) {
    let result = arr[0];
    for (let i = 1; i < arr.length; i++) {
        result = gcd(arr[i], result);

        if (result === 1) {
            return 1;
        }
    }
    return result;
}

function findDenomArray(results) {
    let dn_array = [];
    if (results.length === 1) {
        let count = results[0].count;
        results[0]['total_share'] = 1 * count;
        results[0]['single_share'] = 1;
        lcm = count;
        return;
    }
    for (let i = 0; i < results.length; i++) {
        let a = results[i].share.toFraction();
        let dn = a.split('/')[1];
        if (!dn || Number.isNaN(dn)) {
            dn = 1;
        }
        let numorator = a.split('/')[0];
        // console.log(dn + "  fffffff  " + numorator);
        numorator = Number(numorator);
        dn = Number(dn);
        dn = dn * results[i].count;

        dn_array.push(dn);

    }
    lcm = findlcm(dn_array);
    numeratorArray(results);
    findNumerator(results);

    // console.log(dn_array);
    // console.log(findlcm(dn_array));
}

function numeratorArray(results) {
    let nm_array = [];
    for (let i = 0; i < results.length; i++) {
        let a = results[i].share.toFraction();
        let dn = a.split('/')[1];
        if (!dn || Number.isNaN(dn)) {
            dn = 1;
        }
        let numorator = a.split('/')[0];
        let denom = a.split('/')[1];
        if (!dn || Number.isNaN(denom)) {
            denom = 1;
        }
        numorator = Number(numorator);
        let multiplier = lcm / denom;
        numorator = numorator * multiplier;
        numorator = numorator / results[i].count;
        dn = Number(dn);
        dn = dn * results[i].count;

        nm_array.push(numorator);
    }
    nm_array.push(lcm);
    gcdVal = findGCD(nm_array);
    // console.log(nm_array);
    // console.log(gcdVal + "cduhgcudg");
    // console.log(lcm + "cduhgcudg");
    // console.log("gbugcdsaycdsa");
    findNumerator(results);
}

function findNumerator(results) {

    for (let i = 0; i < results.length; i++) {
        let a = results[i].share.toFraction();
        let numorator = a.split('/')[0];
        let denom = a.split('/')[1];
        let multiplier = lcm / denom;
        numorator = numorator * multiplier;
        // let totalShare = numorator + "/" + lcm;
        let totalShare = (numorator) / gcdVal;
        // let singleShare = (numorator / results[i].count) + "/" + lcm;
        let singleShare = (numorator / results[i].count) / gcdVal;
        results[i]['total_share'] = totalShare;
        results[i]['single_share'] = singleShare;
    }


}

function findNoHeirsList(results,input_heirs){
    no_heirs_list=[];
    const entries = Object.entries(input_heirs);
    entries.forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
        if (value!==0&&value!==undefined) {
            let heirObj = results.find(obj => obj.name === key);
            if (heirObj===undefined){
                no_heirs_list.push(key);
            }
        }


    });
}


const Solution = ({results, total,input_heirs}) => {
    const t = useTranslation();
    findTotalCount(results);
    findDenomArray(results);
    findNoHeirsList(results,input_heirs);
    // console.log(toFraction(0.0375));
    // console.log(results)
    // console.log(input_heirs)
    if (!total) {
        total = 0;
    } else {
        total = total * 1.00;
    }


    return (
        <div className="Solution" dir={t('lang') === 'ml' ? 'ltr' : 'ltr'}>
            <div className="center">
                <img src="/head_total.png" className="img1" alt="logo"/>
                <br/>
            </div>
            {results.length > 0 ? <table className="Solution-header table-width">
                <thead>
                <tr>
                    <th className="f12">{t('heir_type')}</th>
                    <th className="f12">{t('heir_count')}</th>
                    <th className="f12">{t('ones_heir')}</th>
                    <th className="f12">{t('total_heir')}</th>
                    <th className="f12">{t('share_percentage')}</th>
                    {total > 0 && <th className="f12">{t('heir')}</th>}
                </tr>

                </thead>
                <tbody>
                {results.map(r => (

                    !isNaN(r.single_share) ?
                        <tr key={`${r.name}-${r.type}`}>
                            <td className="f12 center">{t(r.name)}</td>
                            <td className="f12 center">{r.count}</td>
                            <td className="f12 center">{r.single_share}/{lcm / gcdVal}</td>
                            <td className="f12 center">{r.total_share}</td>
                            <td className="f12 center">{toPercentage(r.share).toFixed(2)}%</td>
                            {total > 0 &&
                                <td className="f12 center">₹{(total * toPercentage(r.share) / 100).toFixed(2)}</td>}
                        </tr> : ''
                ))
                }
                <tr>
                    <td className="f12 center total-text-color">{t('total')}</td>
                    <td className="f12 center total-text-color">{totalCount}</td>
                    <td className="f12 center total-text-color">{lcm / gcdVal}</td>
                    <td className="f12 center total-text-color">{lcm / gcdVal}</td>
                    <td className="f12 center total-text-color">100%</td>
                    {total > 0 && <td className="f12 center total-text-color">₹{total.toFixed(2)}</td>}
                </tr>



                </tbody>
                <tfoot>
                {no_heirs_list.map(r => (


                    <tr key={`${r}`}>
                        <td className="f12 center" colSpan="3">{t(r)}</td>
                        <td className="f12 center">ഓഹരി ലഭിക്കുകയില്ല</td>
                    </tr>
                ))
                }
                </tfoot>

            </table> : ""}

            {!results.length && <p>{t('no_heir_selected')}</p>}
        </div>
    )
}
export default Solution